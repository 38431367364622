import 'owl.carousel';

export default function home() {
	$( '#home-carousel .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fa fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>',
		],
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		smartSpeed: 1000
	} );
}